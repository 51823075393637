export const environment = {
  production: true,
  clientUrl: 'https://talespinner.io',
  apiUrl: 'https://api.talespinner.io/api',
  jwtDomain: 'api.talespinner.io',
  allowSearchEngineIndexing: true,
  sentry : {
    showDialog: false,
    enabled: true,
    dsn: "https://b5cc0395d6411ca2aab0c61cbb9de992@o4506088050917376.ingest.sentry.io/4506089155526656",
    tracePropagationTargets: ["https://api.talespinner.io/api", "https://talespinner.io"],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  }
};

